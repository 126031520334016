// Import the functions you need from the SDKs you need
// import { initializeApp } from "https://www.gstatic.com/firebasejs/11.3.1/firebase-app.js";
// import { getMessaging, getToken } from "https://www.gstatic.com/firebasejs/11.3.1/firebase-messaging.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import {initializeApp} from "@firebase/app";
import {getMessaging, getToken} from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBhJQv-0R5EXe7uFgLtYaCUY2oRIo0UeMg",
    authDomain: "motoridersuniverse-182811.firebaseapp.com",
    databaseURL: "https://motoridersuniverse-182811.firebaseio.com",
    projectId: "motoridersuniverse-182811",
    storageBucket: "motoridersuniverse-182811.firebasestorage.app",
    messagingSenderId: "89151716072",
    appId: "1:89151716072:web:a26cf6e2abc621b80fe75d",
    measurementId: "G-C4FSDT9R09"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

navigator.serviceWorker.register("/dist/js/notifications_sw.bundle.js").then(registration => {

    getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: 'BII8996bv1l_oakIxLiJ71XlCwFTrx_68GHWlZ9DZMCAkwsT7zEucpn-SjihjBegAe1N-dek0_Zd8TGo_nTCXqo' }
    ).then((currentToken) => {
        if (currentToken) {
            let formData = new FormData;
            formData.set('device_token', currentToken);
            fetch('/ajax/account.registerDevice', {
                method: 'POST',
                body: formData
            }).then();
            // Send the token to your server and update the UI if necessary
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });

})